import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "../Projects/ProjectCards";
import Particle from "../Particle";
import VideoIntro from "../VideoIntro/VideoIntro";
import "./style.css";

function Demo() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading pb-5">
          Demo of our proposal
        </h1>
        <Row>
          <VideoIntro />
        </Row>
        <br>
        </br>
        <br>
        </br>
      </Container>
    </Container >
  );
}

export default Demo;
