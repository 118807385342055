import React from 'react';
import { Row, Col } from "react-bootstrap";
import VIDEOTRAILER from "../../Assets/compass.mp4";

function VideoIntro() {
 const videoIds = ['f8a07wYcDEc', 'vwpFloWt3mQ'];

 return (
  <Row className="justify-content-md-center text-center">
   <Col xs={6} md={6} >
   {/* no start until play */}
    <iframe
     title="Video 1"
     width="100%"
     height="315"
     src={VIDEOTRAILER}
     frameBorder="0"
     allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
     allowFullScreen
    />
   </Col>
  </Row>
 );
}

export default VideoIntro;