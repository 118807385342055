import React, { useState, useEffect } from "react";
import { Container, Row, Button } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf";
import { AiOutlineDownload } from "react-icons/ai";
import Particle from "../Particle";
import pdfFerilli from "../../Assets/cv_ferilli.pdf";
import pdfReDavid from "../../Assets/cv_redavid.pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function ResumeNew() {
  const [width, setWidth] = useState(window.innerWidth);
  const [namePdf, setNamePdf] = useState('');

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const path = window.location.pathname;
    if (path.includes("stefano_ferilli")) {
      setNamePdf("stefano_ferilli");
    } else if (path.includes("domenico_redavid")) {
      setNamePdf("domenico_redavid");
    }
  }, []);

  return (
    <Container fluid>
      <Row className="mt-5">
        <Particle />
      </Row>
      <Container className="resume-section mt-5">
        <h1 className="resume-title m-5" style={{ color: "white" }}>
          {namePdf === "stefano_ferilli" ? "Stefano Ferilli" : "Domenico Re David"}
        </h1>
        {[1, 2, 3, 4, 5, 6, 7, 8].map((pageNumber) => (
          <Row key={pageNumber} className="resume">
            <Document
              file={namePdf === "stefano_ferilli" ? pdfFerilli : pdfReDavid}
              className="d-flex justify-content-center"
            >
              <Page pageNumber={pageNumber} scale={width > 786 ? 1.2 : 0.6} />
            </Document>
          </Row>
        ))}

        <Row className="justify-content-center mt-3">
          <Button
            variant="primary"
            href={namePdf === "stefano_ferilli" ? pdfFerilli : pdfReDavid}
            target="_blank"
            style={{ maxWidth: "250px" }}
          >
            <AiOutlineDownload />
            &nbsp;Download CV
          </Button>
        </Row>
      </Container>
    </Container>
  );
}

export default ResumeNew;
