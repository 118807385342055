import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Github from "./Github";
import Techstack from "./Techstack";
import Aboutcard from "./AboutCard";
import laptopImg from "../../Assets/about.png";
import Toolstack from "./Toolstack";
import ProjectCard from "../Projects/ProjectCards";
import Compass from "../Home/favicon.png";

function About() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              Conference Organization, Management, Processing and Search System
            </h1>
            <Aboutcard />
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px" }}
            className="about-img"
          >
            <img src={Compass} alt="about" className="img-fluid" />
          </Col>
        </Row>
        {/* <h3 className="project-heading">
          Our strength lies in our ability to <strong className="purple">collaborate</strong> and <strong className="purple">innovate</strong>.
        </h3>


        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath="https://static01.nyt.com/images/2022/03/18/opinion/18Exposures-Questlove-slide-ZW8R/18Exposures-Questlove-slide-ZW8R-articleLarge.jpg?quality=75&auto=webp&disable=upscale"
              isBlog={false}
              title="Comprehensive Collection"
              description="Aggregating, organizing, and presenting information and documents to cover the full spectrum of computing history."
              ghLink="https://github.com/soumyajit4419/Chatify"
              demoLink="https://chatify-49.web.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath="https://assets-global.website-files.com/65084cba9d60b1182e9f0021/65258164ea2f6ed1dc7220e4_image_23_e75e8bd7e7.webp"
              isBlog={false}
              title="Collaborative Contributions"
              description="Encouraging a synergistic model where diverse inputs coalesce into a unified narrative, reflecting the vast expanse of computing's legacy."
              ghLink="https://github.com/soumyajit4419/Bits-0f-C0de"
              demoLink="https://blogs.soumya-jit.tech/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath="https://itsm.tools/wp-content/uploads/2019/02/user-experience.jpg"
              isBlog={false}
              title="Enhanced User Experience"
              description="Innovating search tools and personalizing interactions to ensure that users can navigate, discover, and engage with the content effortlessly and meaningfully."
              ghLink="https://github.com/soumyajit4419/Plant_AI"
              demoLink="https://plant49-ai.herokuapp.com/"
            />
          </Col>
        </Row>
         */}
      </Container>
    </Container>
  );
}

export default About;
