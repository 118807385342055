import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import fotoRedavid from "../../Assets/redavid.jpg";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Research Team
        </h1>

        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={3} className="project-card">
            <ProjectCard
              imgPath="https://media.licdn.com/dms/image/C4D03AQFEVzJE9RKCQw/profile-displayphoto-shrink_800_800/0/1602237788823?e=2147483647&v=beta&t=gohPkz2UwpA6-PWXxNNtoM1WWjbfRZkG-GMMqhoJ9gc"
              isBlog={false}
              title="Stefano Ferilli"
              description="Associate Professor at the department of Computer Science, University of Bari Aldo Moro."
              ghLink="/stefano_ferilli"
              demoLink=""
            />
          </Col>

          <Col md={3} className="project-card">
            <ProjectCard
              imgPath={fotoRedavid}
              isBlog={false}
              title="Domenico Re David"
              description="RTDB Researcher at the department of Economics, University of Bari Aldo Moro."
              ghLink="domenico_redavid"
              demoLink=""
            />
          </Col>

          <Col md={3} className="project-card">
            <ProjectCard
              imgPath="https://phd.uniroma1.it/dottorati/cartellaProfili/e732d544-07a2-480e-b5a6-ec87f1c35950.jpg"
              isBlog={false}
              title="Eleonora Bernasconi"
              description="RTDA Researcher at the department of Computer Science, University of Bari Aldo Moro."
              ghLink=""
              demoLink=""
            />
          </Col>

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
