import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Conference Organization, Management, Processing and Search System</p>
          
          <p style={{ color: "rgb(155 126 172)" }}>
          Organizing conferences has become a complex task, requiring the management of numerous interconnected entities and processes. Our project aims to develop an AI-driven Conference Management System designed to support all stakeholders—organizers, evaluators, authors, and participants—by providing advanced tools for handling their tasks and associated materials. The system will leverage AI solutions to help users quickly and accurately find the information they need, whether it is within the conference ecosystem or from external sources in the physical or digital world. This will significantly enhance the efficiency of managing conference-related activities.
          </p>
          <footer className="blockquote-footer">COMPASS</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
