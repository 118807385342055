import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Conference Organization", 
          "Processing and Search System",
          "Personalized Recommendations",
          "Semantic Search",
          "Intelligent Platform",
          "Searching & Browsing",
          "Natural Language Processing",
          "Content Management Systems",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
